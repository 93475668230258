import { AerialClient, ClientExternalPartnersClient } from "../services/ohl.virtualCenter.api";
import moment from 'moment';
const apiClient = new ClientExternalPartnersClient(process.env.REACT_APP_PUBLIC_API);
const apiAerialClient = new AerialClient(process.env.REACT_APP_PUBLIC_API);

class InstallationsProvider {

    getOne = (resource, params) => {
        return apiClient.clientExternalPartners(params.id).then((data) => {
            return { 
                data: {
                    id: data.clientExternalPartners.id,
                    aerialGroupId: data.clientExternalPartners.aerialGroupId,
                    clientId: data.clientId,
                    client:{
                        identificationNumber: data.clientExternalPartners.client?.identificationNumber,
                        name: data.clientExternalPartners?.client?.name,
                        surnames: data.clientExternalPartners?.client?.surnames
                    },
                    residentName: data.clientExternalPartners.residentName,
                    isInstalled: data.clientExternalPartners.isInstalled,
                    baseportMac: data.clientExternalPartners.baseportMac,
                    baseportLocationId: data.clientExternalPartners.baseportLocationId,
                    baseportLocationCustom: data.clientExternalPartners.baseportLocationCustom,
        
                    thing1Name: data.things[0]?.name,
                    thing1Floor: data.things[0]?.floor,
                    thing1DeviceType: data.things[0]?.deviceTypeId,
                    thing1Mac: data.clientExternalPartners.thing1Mac,
                    thing1ModelId: data.clientExternalPartners.thing1ModelId,
                    thing1Serial: data.clientExternalPartners.thing1Serial,
                    thing1Link: !!data.clientExternalPartners.thing1Mac,
                    thing1Sensibility: data.clientExternalPartners.thing1Sensibility,
                    thing1LocationId: data.clientExternalPartners.thing1LocationId,
                    thing1LocationCustom: data.clientExternalPartners.thing1LocationCustom,
        
                    thing2Name: data.things[1]?.name,
                    thing2Floor: data.things[1]?.floor,
                    thing2DeviceType: data.things[1]?.deviceTypeId,
                    thing2Mac: data.clientExternalPartners.thing2Mac,
                    thing2ModelId: data.clientExternalPartners.thing2ModelId,
                    thing2Serial: data.clientExternalPartners.thing2Serial,
                    thing2Link: !!data.clientExternalPartners.thing2Mac,
                    thing2Sensibility: data.clientExternalPartners.thing2Sensibility,
                    thing2LocationId: data.clientExternalPartners.thing2LocationId,
                    thing2LocationCustom: data.clientExternalPartners.thing2LocationCustom,
        
                    thing3Name: data.things[2]?.name,
                    thing3Floor: data.things[2]?.floor,
                    thing3DeviceType: data.things[2]?.deviceTypeId,
                    thing3Mac: data.clientExternalPartners.thing3Mac,
                    thing3ModelId: data.clientExternalPartners.thing3ModelId,
                    thing3Serial: data.clientExternalPartners.thing3Serial,
                    thing3Link: !!data.clientExternalPartners.thing3Mac,
                    thing3Sensibility: data.clientExternalPartners.thing3Sensibility,
                    thing3LocationId: data.clientExternalPartners.thing3LocationId,
                    thing3LocationCustom: data.clientExternalPartners.thing3LocationCustom,
    
                    residenceFloorsNbr: data.groupMeta.residenceFloorsNbr,
                    residentLiveAlone: data.groupMeta.residentLiveAlone,
                    residentHavePet: data.groupMeta.residentHavePet,
                    residenceIsEldercareHome: data.groupMeta.residenceIsEldercareHome,
                    residentPetSize: data.groupMeta.residentPetSizeId,
                    residenceType: data.groupMeta.residenceTypeId,
                    typicalWakeUpTime: formatTime(data.groupMeta.typicalWakeUpTime),
                    typicalBedTime: formatTime(data.groupMeta.typicalBedTime),

                    baseportModelId: data.clientExternalPartners.baseportModelId,
                }
            }
        });
    }

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        if (params.filter && params.filter.initialDate) {
            params.filter.initialDate = moment(params.filter.initialDate).format('yyyy-MM-DDTHH:mm:ss')
        }
        if (params.filter && params.filter.endDate) {
            params.filter.endDate = moment(params.filter.endDate).format('yyyy-MM-DDTHH:mm:ss')
        }

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                total: data.total,
            }
        });
    }

    update = (resource, params) => {
        return apiAerialClient.groupCreationOrquestratorUpdate(params.data).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        return apiAerialClient.groupCreationOrquestratorCreate(params.data).then((data) => ({
            data
        }));
    }

    // delete = (resource, params) => {
    //     return apiClient.installationDelete(params.id).then(() => ({
    //         data: params.previousData
    //     }));
    // }

    
};

function formatTime(time) {
    // Si el campo es nulo o indefinido, retorna nulo
    if (time == null) {
      return null;
    }
  
    // Divide la cadena en partes utilizando ':' como separador
    const parts = time.split(':');
  
    // Retorna solo las dos primeras partes unidas por ':'
    return parts[0] + ':' + parts[1];
  }

export default new InstallationsProvider();