import { AutocompleteInput, Datagrid, SelectInput, ReferenceInput, BooleanInput, TabbedForm, FormTab, List, TextField, FunctionField, Edit, Show, TextInput, Filter, Create, Button, DeleteButton } from "ra-ui-materialui";
import { useState, useCallback, useEffect } from "react";
import { useLocation } from 'react-router';
import {
    useRefresh,
    useNotify,
    useCreate,
    useUpdate,
    useRecordContext
} from "react-admin";
import {
    useRedirect, useMutation,
    // required 
} from "ra-core";
import { PostBulkActionButtonsPermision, CustomToolbarPermissions } from "./utils";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RefreshIcon from "@material-ui/icons/Refresh";
import ActionDelete from '@material-ui/icons/Delete';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment-timezone';
import 'moment-timezone/node_modules/moment/locale/es';
import { TabsUseStyles } from "../App";
import ReactHtmlParser from 'react-html-parser';
import get from 'lodash/get';
import { useTranslate } from 'react-admin';
import { getAerialDeviceTypes, getAerialResidenceType, getAerialResidentPetSize, getBaseportLocations, getCommunicationDevicesPlugModel, getCommunicationDevicesBaseportModel } from "../services/master-tables.service";
import { Button as MUIButton } from '@material-ui/core';

import { saveAs } from 'file-saver';
import { AerialClient, DocumentClient, OhlConstants_TypeDocument } from "../services/ohl.virtualCenter.api";

import { useFormState } from 'react-final-form';

import { hasJustEmptyWhiteSpaces } from "../common/utils";
import { HousingPlanDocumentShow} from './housingPlanDocuments'
import AutoCompleteCustom from "../components/AutoCompleteCustom"

const apiClientDocument = new DocumentClient(process.env.REACT_APP_PUBLIC_API);


const aerialClient = new AerialClient(process?.env?.REACT_APP_PUBLIC_API);

var XLSX = require("xlsx");

export const InstallationList = props => {
    const [baseportLocationList, setBaseportLocationList] = useState();

    useEffect(() => {
        getBaseportLocations().then(
            res => {
                setBaseportLocationList(res.elements);
            }
        )
    }, []);

    const exporter = installations => {
        let installationsList = installations?.map(post => {
            const { id, isDeleted, groupIdAndResidentName, ...installationElement } = post;
            if (installationElement?.baseportLocationId) {
                installationElement.baseportLocationId = getLocationName(installationElement?.baseportLocationId) === "Otro" ? installationElement?.baseportLocationCustom : getLocationName(installationElement?.baseportLocationId);
                installationElement.thing1LocationId = getLocationName(installationElement?.thing1LocationId) === "Otro" ? installationElement?.thing1LocationCustom : getLocationName(installationElement?.thing1LocationId);
                installationElement.thing2LocationId = getLocationName(installationElement?.thing2LocationId) === "Otro" ? installationElement?.thing2LocationCustom : getLocationName(installationElement?.thing2LocationId);
                installationElement.thing3LocationId = getLocationName(installationElement?.thing3LocationId) === "Otro" ? installationElement?.thing3LocationCustom : getLocationName(installationElement?.thing3LocationId);
            }
            if (installationElement?.client) {
                installationElement.client = installationElement?.client?.name + " " + installationElement?.client?.surnames;
            }
            return installationElement;
        });
        installationsList = translateHeaders(installationsList);
        const xlsxBlob = arrayToXlsx(installationsList);
        saveAs(xlsxBlob, 'instalaciones.xlsx');
    };

    const translateHeaders = (installationsList) => {
        for (let i = 0; i < installationsList?.length; i++) {            
            installationsList[i]['Nombre'] = installationsList[i]?.['residentName'];
            delete installationsList[i]?.['residentName'];
            installationsList[i]['Usuario'] = installationsList[i]?.['client'];
            delete installationsList[i]?.['client'];
            installationsList[i]['Grupo'] = installationsList[i]?.['aerialGroupId'];
            delete installationsList[i]?.['aerialGroupId'];
            installationsList[i]['Mac Baseport'] = installationsList[i]?.['baseportMac'];
            delete installationsList[i]['baseportMac'];
            installationsList[i]['Localización Baseport'] = installationsList[i]?.['baseportLocationId'];
            delete installationsList[i]['baseportLocationId'];
            delete installationsList[i]['baseportLocationCustom'];
            installationsList[i]['Mac Enchufe1'] = installationsList[i]?.['thing1Mac'];
            delete installationsList[i]['thing1Mac'];
            installationsList[i]['Serial Enchufe1'] = installationsList[i]?.['thing1Serial'];
            delete installationsList[i]['thing1Serial'];
            //installationsList[i]['Sensibilidad Enchufe1'] = installationsList[i]?.['thing1Sensibility'];
            delete installationsList[i]['thing1Sensibility'];
            installationsList[i]['Localización Enchufe1'] = installationsList[i]?.['thing1LocationId'];
            delete installationsList[i]['thing1LocationId'];
            delete installationsList[i]['thing1LocationCustom'];
            installationsList[i]['Mac Enchufe2'] = installationsList[i]?.['thing2Mac'];
            delete installationsList[i]['thing2Mac'];
            installationsList[i]['Serial Enchufe2'] = installationsList[i]?.['thing2Serial'];
            delete installationsList[i]['thing2Serial'];
            //installationsList[i]['Sensibilidad Enchufe2'] = installationsList[i]?.['thing2Sensibility'];
            delete installationsList[i]['thing2Sensibility'];
            installationsList[i]['Localización Enchufe2'] = installationsList[i]?.['thing2LocationId'];
            delete installationsList[i]['thing2LocationId'];
            delete installationsList[i]['thing2LocationCustom'];
            installationsList[i]['Mac Enchufe3'] = installationsList[i]?.['thing3Mac'];
            delete installationsList[i]['thing3Mac'];
            installationsList[i]['Serial Enchufe3'] = installationsList[i]?.['thing3Serial'];
            delete installationsList[i]['thing3Serial'];
            //installationsList[i]['Sensibilidad Enchufe3'] = installationsList[i]?.['thing3Sensibility'];
            delete installationsList[i]['thing3Sensibility'];
            installationsList[i]['Localización Enchufe3'] = installationsList[i]?.['thing3LocationId'];
            delete installationsList[i]['thing3LocationId'];
            delete installationsList[i]['thing3LocationCustom'];
            installationsList[i]['Situación'] = installationsList[i]?.['isInstalled'] ? 'Domicilio' : 'Stock';
            delete installationsList[i]['isInstalled'];
            delete installationsList[i]['baseportModelId'];
            delete installationsList[i]['thing1ModelId'];
            delete installationsList[i]['thing2ModelId'];
            delete installationsList[i]['thing3ModelId'];
        }
        return installationsList;
    }

    const arrayToXlsx = (array) => {
        const worksheet = XLSX?.utils?.json_to_sheet(array);
        worksheet["!cols"] = [{ wch: 24 }, { wch: 36 }, { wch: 24 }, { wch: 24 }, { wch: 24 }, { wch: 24 }, { wch: 20 },
        { wch: 24 }, { wch: 24 }, { wch: 24 }, { wch: 24 }, { wch: 24 }, { wch: 24 }, { wch: 24 }, { wch: 24 }, { wch: 24 }];
        const workbook = XLSX?.utils?.book_new();
        XLSX?.utils?.book_append_sheet(workbook, worksheet, 'Hoja1');
        const xlsxBlob = XLSX?.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        return new Blob([xlsxBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    };

    const getLocationName = (id) => {
        return baseportLocationList?.find(e => e?.id === id)?.value
    }

    return (
        <List bulkActionButtons={props.permissions?.installations?.delete ? <PostBulkActionButtonsPermision perm="installations.delete" /> : false} exporter={(installations, event) => exporter(installations, event)}
            filters={<InstallationsFilter />} {...props}>
            <Datagrid rowClick={props.permissions?.installations?.edit ? "edit" : "show"} isRowSelectable={record => props.permissions?.installations?.delete}>
                <TextField label="Nombre" source="residentName" style={{ whiteSpace: 'nowrap' }} sortable={true} sortBy="Installation.residentName" />
                <TextField label="Grupo" source="aerialGroupId" />
                <TextField label="Baseport MAC" source="baseportMac" />
                <LocationField label="Baseport Localización" source="baseportLocationId" />
                <FunctionField style={{ whiteSpace: 'nowrap' }} label="Serials" className="capitalize" sortable={true} sortBy="Installation.Serials"
                    render={record => ReactHtmlParser(`${record?.thing1Serial || ''}<br>${record?.thing2Serial || ''}<br>${record?.thing3Serial || '<br>'}`)} />
                <FunctionField style={{ whiteSpace: 'nowrap' }} label="MAC dispositivos" className="capitalize" sortable={true} sortBy="Installation.MACs"
                    render={record => ReactHtmlParser(`${record?.thing1Mac || ''}<br>${record?.thing2Mac || ''}<br>${record?.thing3Mac || '<br>'}`)} />
                <FunctionField style={{ whiteSpace: 'nowrap' }} label="Localizaciones" className="capitalize" sortable={true} sortBy="Installation.ThingLocations"
                    render={record => ReactHtmlParser(`${getLocationName(record?.thing1LocationId) === "Otro" ? record?.thing1LocationCustom : getLocationName(record?.thing1LocationId) || ''
                        }<br>${getLocationName(record?.thing2LocationId) === "Otro" ? record?.thing2LocationCustom : getLocationName(record?.thing2LocationId) || ''
                        }<br>${getLocationName(record?.thing3LocationId) === "Otro" ? record?.thing3LocationCustom : getLocationName(record?.thing3LocationId) || '<br>'}`)} />
                <FunctionField style={{ width: 150 }} label="Usuario" className="capitalize" sortable={true} sortBy="Installation.User"
                    // sortBy="ScheduledActivities.StartDate"
                    render={record => (record?.client?.name || '') + " " + (record?.client?.surnames || '')}
                />
                <FunctionField style={{ whiteSpace: 'nowrap' }} label="Situación" className="capitalize" sortable={true} sortBy="Installation.Situation"
                    render={record => record?.isInstalled ? 'Domicilio' : 'Stock'} />

            </Datagrid>
        </List>
    );
};

export const InstallationEdit = (props) => {

    return (
        <Edit title={<InstallationTitle />} mutationMode="pessimistic" {...props} transform={transformInstallationEdit}>
            {InstallationForm(props, 'edit')}
        </Edit>
    )
};

export const InstallationShow = (props) => {

    return (
        <Show title={<InstallationTitle />} {...props}>
            {InstallationForm(props, 'show')}
        </Show>
    )
};

const InstallationTitle = ({ record }) => {
    return <span>Instalación {`${record?.residentName ? record?.residentName : record?.aerialGroupId}`}</span>;
};

export const InstallationCreate = (props) => {

    return (
        <Create mutationMode="pessimistic" {...props} transform={transformInstallationEdit}>
            {InstallationForm(props, 'create')}
        </Create>
    )
};

const LocationField = (props) => {
    const { className, source, emptyText } = props;
    const record = useRecordContext(props);
    const value = get(record, source);
    const [valueMT, setvalueMT] = useState()
    const translate = useTranslate();
    useEffect(() => {
        getBaseportLocations().then(
            res => {
                let nombre = res?.elements?.find(e => e?.id === value)?.value
                setvalueMT(nombre === "Otro" ? record?.baseportLocationCustom : nombre)
            }
        )
    })

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
        >
            {(valueMT != null && typeof valueMT !== 'string') || valueMT === undefined
                ? JSON?.stringify(valueMT)
                : translate(valueMT) || emptyText}
        </Typography>
    )
}


const CheckSupplyButton = (props) => {
    const { values } = useFormState();
    const notify = useNotify()

    function MacToSerial(mac) {
        var macArray = mac.replace(/[:-]/g, ':').split(':'); // Dividir la MAC en un array de octetos
        var carry = 1; // Inicializar el carry en 1

        // Recorrer la MAC de derecha a izquierda
        for (var i = macArray.length - 1; i >= 0; i--) {
            var valorDecimal = parseInt(macArray[i], 16); // Convertir el octeto hexadecimal en decimal
            var suma = valorDecimal + carry; // Sumar el valor decimal con el carry

            if (suma > 255) {
                carry = 1; // Establecer el carry en 1 si la suma supera 255
                suma = suma % 256; // Obtener el residuo de la división por 256
            } else {
                carry = 0; // Establecer el carry en 0 si la suma no supera 255
            }

            macArray[i] = suma?.toString(16)?.toUpperCase()?.padStart(2, '0'); // Convertir la suma de nuevo a hexadecimal y actualizar el octeto
        }

        return macArray?.join(''); // Unir los octetos en una cadena de MAC nuevamente
    }

    const validarMAC = (mac) => {
        const macRegex = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");
        return macRegex?.test(mac);
    }

    const doCheck = () => {

        if (!validarMAC(values?.baseportMac))
            return notify("Dirección MAC no válida", 'error')

        let serial = MacToSerial(values?.baseportMac) + "-c0"
        aerialClient.checkSupply({ serial: serial })
            .then(thingResponseDto => thingResponseDto?.things)
            .then(things => props?.onSuccess(things))
            .catch((err) => {
                notify(err?.error?.message, 'error')
            })
    }

    return (
        <MUIButton variant="contained" color="primary" onClick={() => doCheck()}
            style={{ height: '3rem', width: 'fit-content', marginTop: '0.5rem' }}>
            Comprobar Aprovisionamiento
        </MUIButton>
    )
}

const RefreshThingMacsButton = (props) => {

    const doRefresh = () => {
        aerialClient.connectedClients({ serial: props?.serial })
            .then(connectedClientsResponseDto => props?.onSuccess(connectedClientsResponseDto?.clients))
    }

    return (
        <MUIButton variant="contained" color="primary" onClick={() => doRefresh()} disabled={props?.disabled}
            style={{ height: '3rem', width: 'fit-content', marginTop: '0.5rem' }}>
            <RefreshIcon />
        </MUIButton>
    )
}

const LocationCustomInput = (props) => {
    const { values } = useFormState();
    const [baseportLocationList, setBaseportLocationList] = useState();

    useEffect(() => {
        getBaseportLocations().then(
            res => {
                setBaseportLocationList(res?.elements);
            }
        )
    }, []);

    const getLocationName = (id) => baseportLocationList?.find(e => e?.id === id)?.value
    return (
        getLocationName(values?.[props?.locationSource]) === "Otro" ?
            <TextInput label="Ubicación Personalizada *" source={props.source} disabled={props.disabled}></TextInput>
            : <span></span>
    )
}

const InstallationForm = (props, mode = 'create') => {
    const classes = TabsUseStyles();

    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();

    const validateInstallation = (values) => {
        const errors = {};
        if (values?.isInstalled) {
            if (!values?.clientId) {
                errors.clientId = "Requerido"
            }
            if (!values?.baseportLocationId) {
                errors.baseportLocationId = "Requerido"
            } else {
                if (getLocationName(values.baseportLocationId) === "Otro") {
                    if (!values?.baseportLocationCustom ||
                        (values?.baseportLocationCustom && hasJustEmptyWhiteSpaces(values?.baseportLocationCustom))) {
                        errors.baseportLocationCustom = "Requerido"
                    }
                }
            }
            if (!values?.residenceFloorsNbr) {
                errors.residenceFloorsNbr = "Requerido"
            }
            if (!values?.residenceType) {
                errors.residenceType = "Requerido"
            }
        } else {
            if (!values?.clientId) {
                if (!values?.residentName?.trim()) {
                    errors.residentName = "Requerido"
                } else if (values?.residentName?.length > 100) {
                    errors.residentName = "No debe sobrepasar los 100 caracteres"
                }
            }
        }
        if (values?.residentHavePet) {
            if (!values?.residentPetSize) {
                errors.residentPetSize = "Requerido"
            }
        }
        if (values?.isInstalled && !values?.typicalWakeUpTime?.trim()) {
            errors.typicalWakeUpTime = "Requerido";
        } else if(values?.typicalWakeUpTime?.trim()) {
            const error = validateTimeField(values, 'typicalWakeUpTime');
            if (error) {
                errors.typicalWakeUpTime = error;
            }
        }
        if (values?.isInstalled && !values?.typicalBedTime?.trim()) {
            errors.typicalBedTime = "Requerido";
        } else if(values?.typicalBedTime?.trim()) {
            const error = validateTimeField(values, 'typicalBedTime');
            if (error) {
                errors.typicalBedTime = error;
            }
        }
        if (values?.thing1Link) {
            if (!values.thing1Name) {
                errors.thing1Name = "Requerido"
            }
            if (!values?.thing1DeviceType) {
                errors.thing1DeviceType = "Requerido"
            }
            if (values?.isInstalled) {
                if (!values?.thing1Mac) {
                    errors.thing1Mac = "Requerido"
                }
                if (!values?.thing1LocationId) {
                    errors.thing1LocationId = "Requerido"
                } else {
                    if (getLocationName(values?.thing1LocationId) === "Otro") {
                        if (!values?.thing1LocationCustom ||
                            (values?.thing1LocationCustom && hasJustEmptyWhiteSpaces(values?.thing1LocationCustom))) {
                            errors.thing1LocationCustom = "Requerido"
                        }
                    }
                }
                if (!values?.thing1Floor) {
                    errors.thing1Floor = "Requerido"
                } else if (values?.thing1Floor > values?.residenceFloorsNbr) {
                    errors.thing1Floor = "La planta indicada supera el numero de plantas indicadas"
                }
            }
            if (values?.thing2Link && values?.thing2Mac === values?.thing1Mac) {
                errors.thing1Mac = "Esta MAC se repite en otro dispositivo"
                errors.thing2Mac = "Esta MAC se repite en otro dispositivo"
            }
            if (values?.thing3Link && values?.thing3Mac === values?.thing1Mac) {
                errors.thing1Mac = "Esta MAC se repite en otro dispositivo"
                errors.thing3Mac = "Esta MAC se repite en otro dispositivo"
            }
        }
        if (values?.thing2Link) {
            if (!values?.thing2Name) {
                errors.thing2Name = "Requerido"
            }
            if (!values?.thing2DeviceType) {
                errors.thing2DeviceType = "Requerido"
            }
            if (values?.isInstalled) {
                if (!values?.thing2Mac) {
                    errors.thing2Mac = "Requerido"
                }
                if (!values?.thing2LocationId) {
                    errors.thing2LocationId = "Requerido"
                } else {
                    if (getLocationName(values?.thing2LocationId) === "Otro") {
                        if (!values?.thing2LocationCustom ||
                            (values?.thing2LocationCustom && hasJustEmptyWhiteSpaces(values?.thing2LocationCustom))) {
                            errors.thing2LocationCustom = "Requerido"
                        }
                    }
                }
                if (!values?.thing2Floor) {
                    errors.thing2Floor = "Requerido"
                } else if (values?.thing2Floor > values?.residenceFloorsNbr) {
                    errors.thing2Floor = "La planta indicada supera el numero de plantas indicadas"
                }
            }
            if (values?.thing3Link && values?.thing3Mac === values?.thing2Mac) {
                errors.thing2Mac = "Esta MAC se repite en otro dispositivo"
                errors.thing3Mac = "Esta MAC se repite en otro dispositivo"
            }
        }
        if (values?.thing3Link) {
            if (!values?.thing3Name) {
                errors.thing3Name = "Requerido"
            }
            if (!values?.thing3DeviceType) {
                errors.thing3DeviceType = "Requerido"
            }
            if (values?.isInstalled) {
                if (!values?.thing3Mac) {
                    errors.thing3Mac = "Requerido"
                }
                if (!values?.thing3LocationId) {
                    errors.thing3LocationId = "Requerido"
                } else {
                    if (getLocationName(values?.thing3LocationId) === "Otro") {
                        if (!values?.thing3LocationCustom ||
                            (values?.thing3LocationCustom && hasJustEmptyWhiteSpaces(values?.thing3LocationCustom))) {
                            errors.thing3LocationCustom = "Requerido"
                        }
                    }
                }
                if (!values?.thing3Floor) {
                    errors.thing3Floor = "Requerido"
                } else if (values?.thing3Floor > values?.residenceFloorsNbr) {
                    errors.thing3Floor = "La planta indicada supera el numero de plantas indicadas"
                }
            }
        }
        return errors
    }

    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'installations',
                    payload: { data: transformInstallationEdit(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props?.basePath, res?.data?.id, res?.data)
                } else {
                    redirect('list', props?.basePath)
                }
            } catch (error) {
                if (error?.error && error?.error?.code === 4003) {
                    //notify(error.error.message, 'error')
                    notify('ra.notification.http_error', 'warning')
                    return {
                        installationNumber: error?.error?.message
                    };
                }
                if (error?.error && error?.error?.code === 4001) {
                    notify(error?.error?.message, 'error')
                    return
                }
            }
        },
        [mutate, mode, notify, props?.basePath, redirect],
    );

    const [baseportLocationList, setBaseportLocationList] = useState();
    const [isBaseportMacValid, setIsBaseportMacValid] = useState(false);
    const [thingsList, setThingsList] = useState([]);
    const [tabHousingPlanVisible, setTabHousingPlanVisible] = useState(false);


    useEffect(() => {
        if (mode !== 'create') {
            setIsBaseportMacValid(true)
        }
        getBaseportLocations().then(
            res => {
                setBaseportLocationList(res.elements);
            }
        )
        // eslint-disable-next-line
    }, []);

    const getLocationName = (id) => baseportLocationList?.find(e => e?.id === id)?.value

    function validateTimeField(values, fieldName) {
        const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;
        const timeValue = values?.[fieldName]?.trim();

        const data = formatTime(timeValue);

        if (!timeFormat.test(data)) {
            return "Formato inválido. Debe ser HH:MM";
        }
        
        return null;
    }

    const onSupplySuccess = (things) => {
        things.sort((a, b) => (a?.serial > b?.serial) ? 1 : ((b?.serial > a?.serial) ? -1 : 0))
        setThingsList(things)
        setIsBaseportMacValid(!isBaseportMacValid)
    }

    const formatMACAddress = (input) => {
        if (!input) return input
        // Eliminar todos los caracteres que no sean hexadecimales
        let cleanInput = input?.replace(/[^a-fA-F0-9]/g, '');

        // Dividir en segmentos de dos caracteres y unir con ":"
        let formattedMAC = cleanInput?.match(/.{1,2}/g)?.join(':') || "";

        // Limitar a 6 segmentos (12 caracteres hexadecimales)
        return formattedMAC.slice(0, 17).toUpperCase();
    }

    return (
        <TabbedForm classes={classes} validate={validateInstallation} save={save} toolbar={isBaseportMacValid && mode !== 'show' ? <CustomToolbarPermissions perm="installations.delete" /> : null}>
            <FormTab label={mode !== 'create' ? "Estado Instalación" : "Datos de la Instalacion"} contentClassName="">
                {
                    mode !== 'create' ?
                        <GroupMotionForm />
                        : !isBaseportMacValid ?
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2rem', width: '100%' }}>
                                <TextInput label="MAC del Baseport" source="baseportMac" format={formatMACAddress}></TextInput>
                                <CheckSupplyButton onSuccess={onSupplySuccess}></CheckSupplyButton>
                            </div>
                            : <BaseportTabContent mode={mode}></BaseportTabContent>
                }
            </FormTab>
            {

                mode !== 'create' && isBaseportMacValid ?
                    <FormTab label="Datos Generales">
                        <BaseportTabContent mode={mode}></BaseportTabContent>
                    </FormTab> : null
            }
            {
                isBaseportMacValid && thingsList[0]?.serial ?
                    <FormTab label={thingsList[0]?.serial}>
                        <ThingTabContent serial={thingsList[0]?.serial} index="1" disabled={mode === 'show'}></ThingTabContent>
                    </FormTab> : null
            }
            {
                isBaseportMacValid && thingsList[1]?.serial ?
                    <FormTab label={thingsList[1]?.serial}>
                        <ThingTabContent serial={thingsList[1]?.serial} index="2" disabled={mode === 'show'}></ThingTabContent>
                    </FormTab> : null
            }
            {
                isBaseportMacValid && thingsList[2]?.serial ?
                    <FormTab label={thingsList[2]?.serial}>
                        <ThingTabContent serial={thingsList[2]?.serial} index="3" disabled={mode === 'show'}></ThingTabContent>
                    </FormTab> : null
            }
            {
                mode !== 'create' && tabHousingPlanVisible ?
                    <FormTab label="Plano Vivienda">
                        <HousingPlanContent props={props} />
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' ?
                    <DummyTabRender onGetList={(list) => setThingsList(list)} onHousingPlan={ (v) => setTabHousingPlanVisible(v)}></DummyTabRender>
                    : null
            }
        </TabbedForm>
    )
}

const HousingPlanContent = (props) => {
    const [documentName, setDocumentName] = useState(null)
    const [firstLoad, setFirstLoad] = useState(false)
    const { values } = useFormState();

    useEffect(() => {
        if (values?.client?.identificationNumber) {
            
            apiClientDocument.generic(values?.client?.identificationNumber, OhlConstants_TypeDocument.HousingPlan).then(
                resp => {
                    var name = resp?.documents[0]?.name;
                    if (name != null) {
                        setDocumentName(resp?.documents[0]?.name);
                    }
                }
            ).finally(
                () => setFirstLoad(true)
            )


        } else {
            setFirstLoad(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.clientId])


    return (
        <>
        { firstLoad && documentName !== null && values?.client?.identificationNumber !== null ?
            <HousingPlanDocumentShow 
            userId={values?.client?.identificationNumber} 
            documentName={documentName} 
            /> : <span>No hay plano de vivienda disponible para el usuario.</span>
        }</>
    )
}

const ThingTabContent = (props) => {

    const [thingMacList, setThingMacList] = useState([]);
    const [baseportLocationList, setBaseportLocationList] = useState();
    const [aerialDeviceTypesList, setAerialDeviceTypesList] = useState();
    const [plugList, setPlugList] = useState();

    const { values } = useFormState();

    useEffect(() => {
        aerialClient?.connectedClients({ serial: props.serial })
            .then(connectedClientsResponseDto => setThingMacList(connectedClientsResponseDto?.clients))
        getBaseportLocations().then(
            res => {
                setBaseportLocationList(res?.elements);
            }
        )
        getAerialDeviceTypes().then(
            res => {
                setAerialDeviceTypesList(res?.elements);
            }
        )
        getCommunicationDevicesPlugModel().then(
            res => {
                setPlugList(res.elements);
            }
        )
        // eslint-disable-next-line
    }, []);

    const onRefreshMacs = (macs) => {
        setThingMacList(macs)
    }

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2rem', alignItems: 'center', justifyContent: 'center' }}>
            <BooleanInput label="Vincular Dispositivo" source={"thing" + props?.index + "Link"} disabled={props.disabled}></BooleanInput>
            <div style={{ display: "flex", gap: '1rem' }} disabled={!values?.["thing" + props?.index + "Link"]}>
                <SelectInput
                    label={`MAC del dispositivo${values?.["thing" + props?.index + "Link"] && values?.isInstalled ? " *" : ""}`}
                    source={"thing" + props?.index + "Mac"}
                    choices={thingMacList}
                    optionText="mac"
                    optionValue="mac"
                    options={{ InputProps: { autoComplete: 'off' } }}
                    //validate={values["thing" + props.index + "Link"]?[required()]:null}
                    disabled={!values?.["thing" + props?.index + "Link"] || props.disabled}
                />
                <RefreshThingMacsButton serial={props?.serial} onSuccess={onRefreshMacs} disabled={!values?.["thing" + props?.index + "Link"] || props.disabled}></RefreshThingMacsButton>
            </div>
            <TextInput
                label={`Nombre del dispositivo${values?.["thing" + props?.index + "Link"] ? " *" : ""}`}
                source={"thing" + props?.index + "Name"}
                disabled={!values?.["thing" + props?.index + "Link"] || props.disabled}></TextInput>
            <AutocompleteInput
                label={`Tipo de Dispositivo${values?.["thing" + props?.index + "Link"] ? " *" : ""}`}
                source={"thing" + props?.index + "DeviceType"}
                choices={aerialDeviceTypesList}
                optionText="value"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
                disabled={!values?.["thing" + props?.index + "Link"] || props.disabled}
            />
            <AutocompleteInput
                label="Modelo de enchufe"
                source={"thing" + props?.index + "ModelId"}
                choices={plugList}
                optionText="value"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
                disabled={props.disabled}
                    />
            <span></span>
            <AutocompleteInput
                label={`Ubicación Enchufe${values?.["thing" + props?.index + "Link"] && values?.isInstalled ? " *" : ""}`}
                source={"thing" + props?.index + "LocationId"}
                choices={baseportLocationList}
                optionText="value"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
                disabled={!values?.["thing" + props?.index + "Link"] || props.disabled}
            />
            <LocationCustomInput
                locationSource={"thing" + props?.index + "LocationId"}
                source={"thing" + props?.index + "LocationCustom"}
                disabled={!values?.["thing" + props?.index + "Link"] || props.disabled}
            ></LocationCustomInput>
            <SelectInput
                label={`Planta${values?.["thing" + props?.index + "Link"] && values?.isInstalled ? " *" : ""}`}
                source={"thing" + props?.index + "Floor"}
                choices={[
                    { id: 1, value: "1" }, { id: 2, value: "2" },
                    { id: 3, value: "3" }, { id: 4, value: "4" },
                    { id: 5, value: "5" }
                ]}
                optionText="value"
                //optionText={}
                inputText={choice => `${choice?.value}`}
                matchSuggestion={(filterValue, suggestion) => {
                    return suggestion ? suggestion?.value?.includes(filterValue) : false
                }}
                optionValue="id"
                // resettable={true}
                suggestionLimit={25}
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
                disabled={!values?.["thing" + props?.index + "Link"] || props.disabled}
            />
            <SelectInput label="Sensibilidad del dispositivo"
                source={"thing" + props?.index + "Sensibility"}
                //validate={values["thing" + props.index + "Link"]?[required()] : null}
                disabled={!values["thing" + props?.index + "Link"] || props.disabled}
                choices={[
                    { id: 0, name: 'Baja' },
                    { id: 1, name: 'Medio-Baja' },
                    { id: 2, name: 'Media' },
                    { id: 3, name: 'Medio-Alta' },
                    { id: 4, name: 'Alta' },
                ]}
            />

            <TextInput label="Serial" source={"thing" + props?.index + "Serial"} defaultValue={props?.serial} disabled style={{ visibility: "hidden" }} ></TextInput>
        </div>
    )
}

const formatTime = (input) => {
    if (!input) return input;

    // Eliminar todos los caracteres que no sean números
    let cleanInput = input.replace(/[^0-9]/g, '');

    // Limitar la entrada a 4 caracteres (HHMM)
    if (cleanInput.length > 4) {
        cleanInput = cleanInput.slice(0, 4);
    }

    // Insertar ":" después de los primeros dos caracteres
    if (cleanInput.length > 2) {
        cleanInput = cleanInput.slice(0, 2) + ':' + cleanInput.slice(2);
    }

    // Validar la hora
    const [hours, minutes] = cleanInput.split(':');

    // Validar que las horas no excedan 23
    if (parseInt(hours, 10) > 24) {
        return '';
    }

     // Validar que los minutos no excedan 59
    if (minutes && parseInt(minutes, 10) > 59) {
        return `${hours}:`;
    }

    // Validar que la hora "24:00" sea la única hora permitida que empieza con 24
    if (hours === '24' && minutes !== '00') {
        return '';
    }

    return cleanInput;
};

const BaseportTabContent = (props) => {
    const record = useRecordContext();

    const [baseportLocationList, setBaseportLocationList] = useState();
    const [aerialResidentPetSizeList, setAerialResidentPetSizeList] = useState();
    const [aerialResidenceTypeList, setAerialResidenceTypeList] = useState();
    const [residentHavePet, setResidentHavePet] = useState(record.residentHavePet);
    const [isInstalled, setIsInstalled] = useState(record.isInstalled);
    const [clientId] = useState(record.clientId);
    const [basePortList, setBasePortList] = useState();

    const { values } = useFormState();


    useEffect(() => {
        getBaseportLocations().then(
            res => {
                setBaseportLocationList(res?.elements);
            }
        )
        getAerialResidentPetSize().then(
            res => {
                setAerialResidentPetSizeList(res?.elements);
            }
        )
        getAerialResidenceType().then(
            res => {
                setAerialResidenceTypeList(res?.elements);
            }
        )
        getCommunicationDevicesBaseportModel().then(
            res => {
                setBasePortList(res.elements);
            }
        )
        
        // eslint-disable-next-line
    }, []);

    
    
    
    

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2rem', alignItems: 'center', justifyContent: 'center' }}>
            <TextInput label="MAC del Baseport" source="baseportMac" disabled></TextInput>
            <TextInput label="Id de Grupo" source="aerialGroupId" disabled></TextInput>
            <SelectInput label="Situación Instalación" source="isInstalled" disabled={props.mode === 'show'}
                onChange={(event) => setIsInstalled(event.target.value)}
                choices={[
                    { id: true, name: 'Domicilio' },
                    { id: false, name: 'Stock' },
                ]} />
            <span></span>
           
            <AutoCompleteCustom 
                type={"USERS"} 
                label={`Usuario Asignado${isInstalled?" *":""}`} 
                placeholder={"Buscar usuario"} 
                disabled={props.mode !== 'create'} 
                source={"clientId"}
                requiredInput={false}
                {...props} />

            {
                !values?.clientId && !values?.isInstalled ?
                    <TextInput label={`Nombre${!isInstalled && !clientId ? " *" : ""}`} source="residentName"
                        disabled={!!values?.clientId || props.mode === 'show'}>
                    </TextInput>
                    : <span></span>
            }
            <AutocompleteInput
                label={`Ubicación Baseport${isInstalled ? " *" : ""}`}
                source="baseportLocationId"
                choices={baseportLocationList}
                optionText="value"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
                disabled={props.mode === 'show'}
            />
            <AutocompleteInput
                        label="Modelo de Baseport"
                        source="baseportModelId"
                        choices={basePortList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                        disabled={props.mode === 'show'}
                    />
            <span></span>
            <LocationCustomInput locationSource="baseportLocationId" source="baseportLocationCustom" disabled={props.mode === 'show'}></LocationCustomInput>
            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', whiteSpace: "nowrap" }}>
                <BooleanInput label="Vive solo" source="residentLiveAlone" disabled={props.mode === 'show'}></BooleanInput>
                <BooleanInput label="Vive en Residencia" source="residenceIsEldercareHome" disabled={props.mode === 'show'}></BooleanInput>
                <BooleanInput label="Tiene Mascota" source="residentHavePet" onChange={value => setResidentHavePet(value)} disabled={props.mode === 'show'}></BooleanInput>
            </div>
            {
                values?.residentHavePet ?
                    <AutocompleteInput
                        label={`Tamaño de la mascota${residentHavePet ? " *" : ""}`}
                        source="residentPetSize"
                        choices={aerialResidentPetSizeList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                        disabled={props.mode === 'show'}
                    />
                    : <span></span>
            }
            <SelectInput
                label={`Plantas de la vivienda${isInstalled ? " *" : ""}`}
                source="residenceFloorsNbr"
                choices={[
                    { id: 1, value: "1" }, { id: 2, value: "2" },
                    { id: 3, value: "3" }, { id: 4, value: "4" },
                    { id: 5, value: "5" }
                ]}
                optionText="value"
                inputText={choice => `${choice?.value}`}
                matchSuggestion={(filterValue, suggestion) => {
                    return suggestion ? suggestion?.value?.includes(filterValue) : false
                }}
                optionValue="id"
                suggestionLimit={25}
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
                disabled={props.mode === 'show'}
            />
            <AutocompleteInput
                label={`Tipo de Vivienda${isInstalled ? " *" : ""}`}
                source="residenceType"
                choices={aerialResidenceTypeList}
                optionText="value"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
                disabled={props.mode === 'show'}
            />
            <>
                <TextInput style={{ marginRight: 5 }} label={`Se despierta (HH:MM)${isInstalled ? " *" : ""}`} source="typicalWakeUpTime" format={formatTime}
                disabled={props.mode === 'show'}></TextInput >
                <TextInput style={{ marginRight: 5 }} label={`Se duerme (HH:MM)${isInstalled ? " *" : ""}`} source="typicalBedTime" format={formatTime}
                disabled={props.mode === 'show'}></TextInput>
            </>
            <TextInput label="GroupId" source="aerialGroupId" disabled style={{ visibility: "hidden" }}></TextInput>
            
        </div>
    )
}


const DummyTabRender = (props) => {

    const { values } = useFormState();


    useEffect(() => {
        if (values.thing1Serial)

            props.onGetList([{ serial: values?.thing1Serial }, { serial: values?.thing2Serial }, { serial: values?.thing3Serial }])
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        
        
        props.onHousingPlan(!!values?.clientId);
        // eslint-disable-next-line
    }, [values?.clientId]);

    return null;
}

const GroupMotionForm = (props) => {
    const record = useRecordContext();
    const [groupMotionStatus, setGroupMotionStatus] = useState();
    const [thingMotionStatus, setThingMotionStatus] = useState({}, {}, {});
    const [diagnosticData, setDiagnosticData] = useState([]);

    const [baseportLocationList, setBaseportLocationList] = useState();
    const [deviceTypeList, setDeviceTypeList] = useState();

    useEffect(() => {
        getBaseportLocations().then(
            res => {
                setBaseportLocationList(res.elements);
            }
        );

        getAerialDeviceTypes().then(
            res => {
                setDeviceTypeList(res.elements);
            }
        );


        if (record?.aerialGroupId) {
            aerialClient.groupMotionStatus(record?.aerialGroupId).then(res => {
                setGroupMotionStatus(res)
            });
            for (let i = 1; i <= 3; i++) {
                const linkKey = `thing${i}Link`;
                const serialKey = `thing${i}Serial`;

                if (record[linkKey]) {
                    getThingMotionData(record?.[serialKey], i - 1);
                }
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (baseportLocationList && baseportLocationList.length > 0) {
            let promises = [];

            for (let index = 0; index < 3; index++) {
                if (record?.aerialGroupId && record?.["thing" + (index + 1) + "Serial"]) {

                    promises.push(aerialClient?.thingDiagnostic(record?.["thing" + (index + 1) + "Serial"]));
                }
            }

            Promise.all(promises).then((results) => {
                setDiagnosticData(results);
            });
        }
        // eslint-disable-next-line
    }, [baseportLocationList]);

    const getLocationName = (id, customLocation) => {
        return baseportLocationList?.find(e => e?.id === id)?.value !== "Otro" ? baseportLocationList?.find(e => e?.id === id)?.value : customLocation;
    }

    const getDeviceTypeName = (id) => {
        return deviceTypeList?.find(e => e?.id === id)?.value;
    }

    const getGroupMotionStatus = () => {
        if (record?.aerialGroupId) {
            setGroupMotionSpinner(true)
            aerialClient.groupMotionStatus(record?.aerialGroupId).then(res => {
                setGroupMotionStatus(res)
            }).finally(() => setGroupMotionSpinner(false));
        }
    }

    const getDiagnosticData = (thingSerial, index) => {
        if (record?.aerialGroupId) {
            let newSpinners = { ...spinners }
            newSpinners["thing" + index] = true
            setSpinners(newSpinners)
            aerialClient.thingDiagnostic(thingSerial).then(
                res => {
                    let diagnosticDataCopy = JSON?.parse(JSON?.stringify(diagnosticData));
                    diagnosticDataCopy[index] = res;
                    if (record?.aerialGroupId) {
                        aerialClient?.thingDiagnostic(thingSerial).then(
                            res => {
                                diagnosticDataCopy[index] = res;
                                setDiagnosticData(diagnosticDataCopy);
                            }
                        );
                    }
                }
            ).finally(() => {
                let newSpinners = { ...spinners }
                newSpinners["thing" + index] = false
                setSpinners(newSpinners)
            })
        }
    }

    const getThingMotionData = (thingSerial, index) => {
        if (record?.aerialGroupId) {
            aerialClient?.thingDiagnostic(thingSerial).then(
                res => {
                    let motionDataCopy = JSON?.parse(JSON?.stringify(diagnosticData));
                    motionDataCopy[index] = res;
                    if (record?.aerialGroupId) {
                        aerialClient.thingDiagnostic(thingSerial).then(
                            res => {
                                motionDataCopy[index] = res;
                                setThingMotionStatus(motionDataCopy);
                            }
                        );
                    }
                }
            );
        }
    }
    const getAllThingData = (thingSerial, index) => {
        getDiagnosticData(thingSerial, index);
        getThingMotionData(thingSerial, index);
    }

    const assignColorStatus = (statusMessage, hasLinked) => {
        if (!hasLinked) return "red";
        switch (statusMessage) {
            case statusMessage?.toLowerCase(statusMessage)?.includes('error'):
                return 'red';
            case 'INITIALIZING':
                return 'blue';
            case 'NORMAL':
                return 'green';
            default:
                return 'orange';
        }
    }

    const assignColorSignal = (statusMessage) => {
        switch (statusMessage) {
            case statusMessage?.toLowerCase(statusMessage)?.includes('error'):
                return 'red';
            case 'OK':
                return 'green';
            default:
                return 'orange';
        }
    }

    const [spinners, setSpinners] = useState({});
    const [groupMotionSpinner, setGroupMotionSpinner] = useState(false);

    return (
        <div>
            <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr' }}>
                <div></div>
                <div style={{ textAlign: 'center' }}>
                    <p style={{ fontSize: '1rem', fontWeight: 'bolder' }}>
                        {groupMotionStatus ?
                            groupMotionStatus?.humanMotionDetected ?
                                `El hogar tiene actividad` :
                                `El hogar está tranquilo`
                            : ""
                        }
                    </p>
                    <p style={{ fontSize: '0.8rem' }}>
                        {groupMotionStatus ?
                            groupMotionStatus?.humanMotionDetected ?
                                `${moment(groupMotionStatus?.timestamp?.toString()).utc().tz("Europe/Madrid").format('HH:mm:ss')} el día ${moment(groupMotionStatus?.timestamp?.toString()).format('DD/MM/YYYY')}` :
                                `${moment(groupMotionStatus?.timestamp?.toString()).utc().tz("Europe/Madrid").format('HH:mm:ss')} el día ${moment(groupMotionStatus?.timestamp?.toString()).format('DD/MM/YYYY')}`
                            : ""
                        }
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ marginTop: '1.5rem', cursor: 'pointer' }} variant="contained" color="primary" onClick={getGroupMotionStatus}>
                            <RefreshIcon className={groupMotionSpinner ? 'spin' : ''}></RefreshIcon>
                        </span>
                    </div>
                </div>
                <div></div>
            </div>

            <div style={{ display: "grid", gridTemplateColumns: '1fr' }}>
                <div key={"gridThing"} >
                    <br />
                    {
                        diagnosticData?.map((thing, index) => {
                            if (record?.['thing' + (index + 1) + 'Serial']) {
                                return (
                                    <>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>

                                                {record?.['thing' + (index + 1) + 'Link'] ?
                                                    <>
                                                        <p style={{ marginLeft: '5px', fontSize: '0.8rem' }}>
                                                            <p style={{ paddingTop: '0.2rem', paddingBottom: '0.2rem' }}>
                                                                {record?.["thing" + (index + 1) + "Serial"]}
                                                            </p>
                                                            <p style={{ paddingTop: '0.2rem', paddingBottom: '0.2rem' }}>
                                                                <span>{getDeviceTypeName(record?.["thing" + (index + 1) + "DeviceType"])}</span> -
                                                                <span> {record?.["thing" + (index + 1) + "Mac"]}</span> -
                                                                <span> {getLocationName(record?.["thing" + (index + 1) + "LocationId"], record?.["thing" + (index + 1) + "LocationCustom"])}</span>
                                                            </p>
                                                            {/* <p style={{paddingTop: '0.2rem', paddingBottom: '0.2rem'}}>
                                                            <b>{record?.["thing" + (index + 1) + "Name"]}</b>
                                                        </p> */}
                                                            <p style={{ paddingTop: '0.2rem', paddingBottom: '0.2rem' }}>
                                                                El enchufe
                                                                {thingMotionStatus?.[index]?.humanMotionDetected ? ' ha ' : ' no ha '}
                                                                detectado movimiento
                                                            </p>
                                                        </p>
                                                    </>
                                                    : <p style={{ marginLeft: '5px', fontSize: '0.8rem' }}>{record["thing" + (index + 1) + "Serial"]} : No tiene ningún enchufe vinculado</p>
                                                }

                                            </div>
                                            {record?.['thing' + (index + 1) + 'Link'] ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <span variant="contained" color="inherit" style={{ cursor: 'pointer' }}
                                                        onClick={() => getAllThingData(record?.["thing" + (index + 1) + "Serial"], index)}>
                                                        <RefreshIcon className={spinners["thing" + index] ? 'spin' : ''}></RefreshIcon>
                                                    </span>
                                                </div>
                                                : null}
                                            {record?.['thing' + (index + 1) + 'Link'] ?
                                                <div>
                                                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '2rem' }}>
                                                        <RadioButtonCheckedIcon style={{ color: assignColorStatus(thing?.statusMessage, record?.['thing' + (index + 1) + 'Link'] ? true : false), fontSize: '1rem' }} />
                                                        <p style={{ marginLeft: '5px', fontSize: '0.8rem' }}>{thing?.statusMessage}: {thing?.connected ? "Conectado" : "No conectado"}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '2rem' }}>
                                                        <RadioButtonCheckedIcon style={{ color: assignColorSignal(thing?.signal?.strength?.statusMessage), fontSize: '1rem' }} />
                                                        <p style={{ marginLeft: '5px', fontSize: '0.8rem' }}>Fuerza de la señal {thing?.signal?.strength?.statusMessage} {thing?.signal?.strength?.value} (Aceptable {thing?.signal?.strength?.rangeAcceptableMax}/{thing?.signal?.strength?.rangeAcceptableMin})</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '2rem' }}>
                                                        <RadioButtonCheckedIcon style={{ color: assignColorSignal(thing?.signal?.strength?.statusMessage), fontSize: '1rem' }} />
                                                        <p style={{ marginLeft: '5px', fontSize: '0.8rem' }}>Calidad de la señal {thing?.signal?.quality?.statusMessage} {thing?.signal?.quality?.value} (Aceptable {thing?.signal?.quality?.rangeAcceptableMax}/{thing?.signal?.quality?.rangeAcceptableMin})</p>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                        {index !== diagnosticData?.length - 1 ? <hr></hr> : null}
                                    </>
                                );
                            } else {
                                return null;
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
};

const InstallationsFilter = (props) => {
    const [baseportLocationList, setBaseportLocationList] = useState()

    useEffect(() => {
        getBaseportLocations().then(
            res => {
                setBaseportLocationList(res?.elements);
            }
        )
    })
    return (
        <Filter {...props}>
            {/* <TextInput label="NIF/NIE" source="identificationNumber" alwaysOn /> */}
            <TextInput label="Nombre Instalación" source="name" alwaysOn />
            <TextInput label="Grupo" source="aerialGroupId" alwaysOn />
            <SelectInput style={{ minWidth: 175 }} label="Asignado a Usuario" source="hasUserAssigned" alwaysOn choices={[
                { id: true, name: 'Asignados' },
                { id: false, name: 'Sin Asignar' },
            ]} />
            <ReferenceInput label="Usuario" source="clientId" reference="users" allowEmpty={false} perPage={1000} alwaysOn>
                <AutocompleteInput optionText={(record) => record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames} resettable={true} />
            </ReferenceInput>
            <TextInput label="Baseport MAC" source="baseportMac" />
            <AutocompleteInput
                label="Baseport Localización"
                source="baseportLocation"
                choices={baseportLocationList}
                optionText="value"
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
                resettable={true}
            />
            <TextInput label="MAC dispositivos" source="thing1Mac" />
            <TextInput label="Serial" source="serial" />
            <SelectInput style={{ minWidth: 185 }} label="Situación Instalación" source="isInstalled" choices={[
                { id: true, name: 'Domicilio' },
                { id: false, name: 'Stock' },
            ]} />
        </Filter>
    );
}

// const PostCreateToolbar = props => {
//     return (
//         <Toolbar {...props} >
//             <SaveButton
//                 label="Guardar"
//                 redirect={props.redirect}
//                 submitOnEnter={true}
//             />
//             <Button 
//                 label="Volver" 
//                 component={Link}
//                 to={{
//                     pathname: props.redirect,
//                     // Here we specify the initial record for the create view
//                 }}
//             ></Button>
//         </Toolbar>
//     )
// };

export const AddNewInstallationsButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [create, { loading }] = useCreate('installations');

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form?.getState()?.dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: { ...values, clientId: record?.id } } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error?.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                onClick={handleClick}
                label="Agregar instalación"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder agregar instalaciones es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Añadir instalación</DialogTitle>
                <DialogContent>
                    <InstallationForm save={handleSubmit} redirect={location?.pathname} mode="create" loading={loading} handleCloseClick={handleCloseClick}></InstallationForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

export const DeleteInstallationsButton = ({ record }) => {
    const location = useLocation();
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (form?.getState()?.dirty) {
        return (
            <>
                <Button
                    onClick={handlePopOverClick}
                    label="Borrar"
                    className="my-ra-delete-button"
                    key="button"
                    color="error"
                >
                    <ActionDelete />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder eliminar instalaciones es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
            </>
        )
    } else {
        return (
            <>
                <DeleteButton
                    mutationMode="pessimistic"
                    resource="installations"
                    record={record}
                    redirect={location?.pathname}
                    label="borrar instalación"
                >
                    <AccountCircleIcon />
                </DeleteButton>
            </>
        )
    }
};

export const EditInstallationsButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [edit, { loading }] = useUpdate('installations');

    const handlePopOverClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        edit(
            { payload: { data: transformInstallationEdit(values) } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error?.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                onClick={handleClick}
                label="Editar instalación"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder editar instalaciones es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Editar instalación</DialogTitle>
                <DialogContent>
                    <InstallationForm
                        save={handleSubmit}
                        redirect={location?.pathname}
                        mode="create"
                        loading={loading}
                        handleCloseClick={handleCloseClick}
                        initialValues={record}
                    ></InstallationForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

const transformInstallationEdit = (data) => {
    let initHour = data?.typicalWakeUpTime
    let endHour = data?.typicalBedTime
    // const initialDate = data.initialDate ? moment(data.initialDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    // const endDate = data.endDate ? moment(data.endDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    let thinglist = []
    let supplycount = data?.thing1Serial ? 1 : 0
    supplycount += data?.thing2Serial ? 1 : 0
    supplycount += data?.thing3Serial ? 1 : 0
    for (let i = 0; i < supplycount; i++) {
        let t = {
            name: data?.[`thing${i + 1}Link`] ? data?.[`thing${i + 1}Name`] : '',
            serial: data?.[`thing${i + 1}Serial`],
            deviceTypeId: data?.[`thing${i + 1}Link`] ? data?.[`thing${i + 1}DeviceType`] : null,
            locationId: data?.[`thing${i + 1}Link`] ? data?.[`thing${i + 1}LocationId`] : null,
            locationCustom: data?.[`thing${i + 1}Link`] ? data?.[`thing${i + 1}LocationCustom`] : '',
            floor: data?.[`thing${i + 1}Link`] ? data?.[`thing${i + 1}Floor`] : 0,
            sensibility: data?.[`thing${i + 1}Link`] ? data?.[`thing${i + 1}Sensibility`] ? data?.[`thing${i + 1}Sensibility`] : 2 : 2,
            mac: data?.[`thing${i + 1}Link`] ? data?.[`thing${i + 1}Mac`] : '',
        }
        thinglist.push(t)
    }
    return {
        groupId: data?.aerialGroupId,
        clientId: data?.clientId,
        clientExternalPartners: {
            aerialGroupId: data?.aerialGroupId,
            residentName: data?.residentName,
            isInstalled: data?.isInstalled,
            baseportMac: data?.baseportMac,
            baseportLocationId: data?.baseportLocationId,
            baseportLocationCustom: data?.baseportLocationCustom?.trim(),

            thing1Mac: data?.thing1Link ? data?.thing1Mac : '',
            thing1Serial: data?.thing1Serial,
            thing1ModelId: data?.thing1ModelId,
            thing1Sensibility: data?.thing1Link ? data?.thing1Sensibility ? data?.thing1Sensibility : 2 : 2,
            thing1LocationId: data?.thing1Link ? data?.thing1LocationId : null,
            thing1LocationCustom: data?.thing1Link ? data?.thing1LocationCustom?.trim() : '',

            thing2Mac: data?.thing2Link ? data?.thing2Mac : '',
            thing2Serial: data?.thing2Serial,
            thing2ModelId: data?.thing2ModelId,
            thing2Sensibility: data?.thing2Link ? data?.thing2Sensibility ? data?.thing2Sensibility : 2 : 2,
            thing2LocationId: data?.thing2Link ? data?.thing2LocationId : null,
            thing2LocationCustom: data?.thing2Link ? data?.thing2LocationCustom?.trim() : '',

            thing3Mac: data?.thing3Link ? data?.thing3Mac : '',
            thing3Serial: data?.thing3Serial,
            thing3ModelId: data?.thing3ModelId,
            thing3Sensibility: data?.thing3Link ? data?.thing3Sensibility ? data?.thing3Sensibility : 2 : 2,
            thing3LocationId: data?.thing3Link ? data?.thing3LocationId : null,
            thing3LocationCustom: data?.thing3Link ? data?.thing3LocationCustom?.trim() : '',
            
            baseportModelId: data?.baseportModelId
        },
        groupMeta: {
            residentName: data?.residentName,
            residenceFloorsNbr: data?.residenceFloorsNbr,
            residentLiveAlone: data?.residentLiveAlone,
            residentHavePet: data?.residentHavePet,
            residenceIsEldercareHome: data?.residenceIsEldercareHome,
            residentPetSizeId: data?.residentPetSize,
            residenceTypeId: data?.residenceType,
            typicalWakeUpTime: initHour,
            typicalBedTime: endHour,
        },
        things: thinglist
    }
}