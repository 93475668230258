// in src/App.js
import * as React from "react";
import { Admin } from 'react-admin';
import { Route } from 'react-router-dom';

import authProvider from "./provider/auth.provider";
import i18nProvider from "./provider/i18n.provider";
import { ResourceWithPermissions } from "ra-auth-acl";

import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import PostIcon from '@material-ui/icons/Book';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import Airplay from '@material-ui/icons/Airplay';
import DescriptionIcon from '@material-ui/icons/Description';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import RouterIcon from '@material-ui/icons/Router';
import CameraIcon from '@material-ui/icons/Camera';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';

import BaseProvider from "./provider/base.provider";

import { usuarioCreate as UsuarioCreate, usuarioEdit as UsuarioEdit, UsuarioList, usuarioShow as UsuarioShow } from "./resources/user";
import { ActivityCreate, ActivityEdit, ActivityShow, ActivityList } from "./resources/activities";
import { RecordCreate, RecordEdit } from "./resources/records";
import { ProfessionalCreate, ProfessionalEdit, ProfessionalList, ProfessionalShow } from "./resources/professional";
import { AdminCreate, AdminEdit, AdminList } from "./resources/admins";
import { CalendarList, ScheduledActivitiesList, ScheduledActivitiesOldList, ScheduledActivitiesOldShow, ScheduledActivitiesShow } from "./resources/scheduledActivities";
import { ContactList, ContactCreate, ContactEdit } from "./resources/contacts";
import { ContractList, ContractCreate, ContractEdit, ContractShow } from "./resources/contracts";
import { ContractingAuthorityList, ContractingAuthorityCreate, ContractingAuthorityEdit } from "./resources/contractingAuthorities";
import { DeviceCreate, DeviceEdit, DeviceList } from "./resources/devices";
import { ActivitiesCall } from "./resources/activitiesCall";
import { RouterDeviceCreate, CameraDeviceCreate, ComunicationDeviceEdit, ComunicationDeviceList } from "./resources/comunicationDevices";
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { defaultTheme } from "react-admin";
import { UserInteractionsCreate, UserInteractionsEdit } from "./resources/userInteractions";
import { MasterTableList } from "./resources/masterTable";
import Login from "./resources/login";
import ChangePasswordPage from "./resources/changePasswordPage";
import { AerialWebView } from './resources/WebView/AerialWebView';
// import { customRoutes } from "./resources/routes";
import { MyLayout } from "./CustomAppbar";
import { SuperAdminCreate, SuperAdminEdit, SuperAdminList } from "./resources/superAdmins";
import SessionExpireDialog from "./components/SessionExpireDialog";
import { InstallationCreate, InstallationEdit, InstallationList, InstallationShow } from "./resources/installations";
import { Footer } from "./components/Footer/Footer";

import CustomNestedMenu from "./components/CustomNestedMenu";
import { HomeList } from "./resources/home";

export const TabsUseStyles = makeStyles(
  theme => ({
    errorTabButton: { color: "#f44336 !important" },
  }),
  { name: 'RaTabbedForm' }
);

export const ChipArrayUseStyles = makeStyles(
  theme => ({
    chipContainerFilled: { margin: "15px 0px 0px 0px", flexWrap: 'wrap' },
  }),
  { name: 'RaAutocompleteArrayInput' }
);

const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      light: '#00ab8e',
      main: '#00ab8e',
      dark: '#00ab8e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#003488',
      main: '#003488',
      dark: '#003488',
      contrastText: '#fff',
    },
  },
  sidebar: {
    width: 250, // The default value is 240
    closedWidth: 55, // The default value is 55
  },
  menu: {
    width: 250
  }
});

const customRoutes = [
  <Route noLayout exact path="/activityCall/:activityId/:token" component={ActivitiesCall} />,
  <Route noLayout exact path="/changePasswordPage" component={ChangePasswordPage} />,
  <Route noLayout exact path="/AerialHabits/:aerialGroupId/:currentStartDate/:currentEndDate/:habitSelector/:activeTab/:token" component={AerialWebView} />
  // <Route noLayout exact path="/AerialHabits" component={AerialWebView} />
]

const App = () => (
  <>
  <SessionExpireDialog></SessionExpireDialog>
  <Admin
    customRoutes={customRoutes}
    layout={MyLayout}
    theme={theme}
    dataProvider={BaseProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    loginPage={Login}
    changePasswordPage={ChangePasswordPage}
    menu={CustomNestedMenu}
  >
    {permissions => {
      const defaultResources = [
        <ResourceWithPermissions
          name="users"
          permissions={permissions}
          options={{ label: 'Usuarios' }}
          list={UsuarioList}
          edit={UsuarioEdit}
          show={UsuarioShow}
          create={UsuarioCreate}
          icon={PersonIcon}
        />,
        <ResourceWithPermissions
          name="admins"
          permissions={permissions}
          options={{ label: 'Administradores' }}
          list={AdminList}
          edit={AdminEdit}
          create={AdminCreate}
          icon={SupervisorAccountIcon}
        />,
        <ResourceWithPermissions
          name="superAdmins"
          permissions={permissions}
          options={{ label: 'Superadministradores' }}
          list={SuperAdminList}
          edit={SuperAdminEdit}
          create={SuperAdminCreate}
          icon={SupervisorAccountIcon}
        />,
        <ResourceWithPermissions
          name="professional"
          permissions={permissions}
          options={{ label: 'Profesionales' }}
          list={ProfessionalList}
          edit={ProfessionalEdit}
          show={ProfessionalShow}
          create={ProfessionalCreate}
          icon={RecordVoiceOverIcon}
        />,
        <ResourceWithPermissions
          name="activities"
          permissions={permissions}
          options={{ label: 'Actividades' }}
          list={ActivityList}
          edit={ActivityEdit}
          show={ActivityShow}
          create={ActivityCreate}
          icon={PostIcon}
        />,
        <ResourceWithPermissions
          name="records"
          permissions={permissions}
          options={{ label: 'Registros' }}
          edit={RecordEdit}
          create={RecordCreate}
          icon={PostIcon}
        />,
        permissions.scheduledActivities.list ?
        <ResourceWithPermissions
          name="scheduledActivities"
          permissions={permissions}
          options={{ label: 'Agenda' }}
          list={ScheduledActivitiesList}
          show={ScheduledActivitiesShow}
          icon={ScheduleIcon}
        /> : null,
          <ResourceWithPermissions
            name="installations"
            permissions={permissions}
            options={{ label: 'Presencia' }}
            list={InstallationList}
            edit={InstallationEdit}
            show={InstallationShow}
            create={InstallationCreate}
            icon={Airplay}
          />,
          <ResourceWithPermissions
            name="devices"
            permissions={permissions}
            options={{ label: 'CSV' }}
            list={DeviceList}
            edit={DeviceEdit}
            create={DeviceCreate}
            icon={PhonelinkRingIcon}
          />,
          <ResourceWithPermissions
            name="comunicationDevices"
            permissions={permissions}
            options={{ label: 'Comunicación' }}
            list={ComunicationDeviceList}
            edit={ComunicationDeviceEdit}
            create={RouterDeviceCreate}
            icon={RouterIcon}
          />,
          <ResourceWithPermissions
            name="cameraDevices"
            permissions={permissions}
            options={{ label: 'Cámaras' }}
            list={ComunicationDeviceList}
            edit={ComunicationDeviceEdit}
            create={CameraDeviceCreate}
            icon={CameraIcon}
          />,
        <ResourceWithPermissions
          name="contacts"
          permissions={permissions}
          options={{ label: 'Contactos' }}
          list={ContactList}
          edit={ContactEdit}
          create={ContactCreate}
          icon={PhonelinkRingIcon}
        />,
        <ResourceWithPermissions
          name="contracts"
          permissions={permissions}
          options={{ label: 'Contratos' }}
          list={ContractList}
          show={ContractShow}
          edit={ContractEdit}
          create={ContractCreate}
          icon={DescriptionIcon}
        />,
        <ResourceWithPermissions
          name="contractingAuthorities"
          permissions={permissions}
          options={{ label: 'Clientes' }}
          list={ContractingAuthorityList}
          edit={ContractingAuthorityEdit}
          create={ContractingAuthorityCreate}
          icon={PermIdentityIcon}
        />,
        <ResourceWithPermissions
          name="userHistory"
          permissions={permissions}
          options={{ label: 'Historia de usuario' }}
        />,
        <ResourceWithPermissions
          name="userInteractions"
          permissions={permissions}
          options={{ label: 'Interacción de usuario' }}
          edit={UserInteractionsEdit}
          create={UserInteractionsCreate}
        />,
        <ResourceWithPermissions
          name="userDocuments"
          permissions={permissions}
          options={{ label: 'Documentos' }}
        />,
        <ResourceWithPermissions
          name="masterTable"
          permissions={permissions}
          options={{ label: 'Tablas maestras' }}
          list={MasterTableList}
          icon={SettingsIcon}
        />
      ]
      if (permissions.home.list) {
        return [
          <ResourceWithPermissions
            name="home"
            permissions={permissions}
            options={{ label: 'Home' }}
            list={HomeList}
            icon={HomeIcon}
          />,
          <ResourceWithPermissions
            name="calendar"
            permissions={permissions}
            options={{ label: 'Calendario' }}
            list={CalendarList}
            icon={CalendarIcon}
          />,
          <ResourceWithPermissions
            name="scheduledActivities"
            permissions={permissions}
            options={{ label: 'Agenda' }}
            list={ScheduledActivitiesList}
            show={ScheduledActivitiesShow}
            icon={ScheduleIcon}
          />,
          <ResourceWithPermissions
            name="scheduledActivitiesOld"
            permissions={permissions}
            options={{ label: 'Histórico' }}
            list={ScheduledActivitiesOldList}
            show={ScheduledActivitiesOldShow}
            icon={HistoryIcon}
          />
        ].concat(defaultResources);
      } else {
        return defaultResources.concat([
          <ResourceWithPermissions
            name="scheduledActivities"
            permissions={permissions}
            options={{ label: 'Agenda' }}
            list={ScheduledActivitiesList}
            show={ScheduledActivitiesShow}
            icon={ScheduleIcon}
          />
        ]);
      }
    }}
  </Admin>
  <Footer />

  </>
);

export default App;
